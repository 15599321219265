import React, {Component, useContext, useState, useEffect} from 'react'
import ls from "local-storage";
import {helpers} from "../../services/api/helpers";
let isAdmin = ls.get('xxxx');

export  const Centre = ()=> {

  const [nbpointage, setNbpointage] = useState(0);
  const [nbagents, setNbagents] = useState(0);
  const [nbvehicules, setNbvehicules] = useState(0);
  const [nbdepot, setNbdepot] = useState(0);
  const [nbrecuperation, setNbrecuperation] = useState(0);
  const [nbvidage, setNbvidage] = useState(0);
  const [nbtypedechet, setNbnbtypedechet] = useState(0);
  const [nbincident, setNbincident] = useState(0);
  const [nbsitetraitement, setNbsitetraitement] = useState(0);
  const [nbprestataire, setNbprestataire] = useState(0);
  const [nbetatdelieu, setNbnbetatdelieu] = useState(0);
  const [nbepi, setNbepi] = useState(0);
  const [nbsite, setNbsite] = useState(0);


  function navigate (e, route){
    e.preventDefault();
   ls.set('navigation', route);

  }

  const getSite = async () => {
    helpers
        .getAllSites()
        .then(async r => {
          if (r.status === 200) {
            setNbsite( r.data.length);
          }
        })
  };

  const getEPI = async () => {
    helpers
        .getEPI()
        .then(async r => {
          if (r.status === 200) {
            setNbepi( r.data.length);
          }
        })
  };
  const getPointages = async () => {
    helpers
        .getpointage()
        .then(async r => {
          if (r.status === 200) {
            setNbpointage( r.data.length);
          }
        })
  };

  const getAgents = async () => {
    helpers
        .getAllAgents()
        .then(async r => {
          if (r.status === 200) {
            setNbagents( r.data.length);
          }
        })
  };


  const getListeVehicules = async () => {
    helpers
        .getAllVehicules()
        .then(async r => {
          if (r.status === 200) {
            setNbvehicules( r.data.length);
          }
        })
  };

  const getDepot = async () => {
    helpers
        .getdonnerie({donnerie: "Dépot"})
        .then(async r => {
          if (r.status === 200) {
            setNbdepot( r.data.length);
          }
        })
  };

  const getRecuperation = async () => {
    helpers
        .getdonnerie({donnerie: "Récupération"})
        .then(async r => {
          if (r.status === 200) {
            setNbrecuperation( r.data.length);
          }
        })
  };
  const getVidage = async () => {
    helpers
        .getvidage()
        .then(async r => {
          if (r.status === 200) {
            setNbvidage( r.data.length);
          }
        })
  };

  const getListeTypeDechets = async () => {
    helpers
        .getAllTypeDechets()
        .then(async r => {
          if (r.status === 200) {
            setNbnbtypedechet( r.data.length);
          }
        })
  };

  const getIncident = async () => {
    helpers
        .getincident()
        .then(async r => {
          if (r.status === 200) {
            setNbincident( r.data.length);
          }
        })
  };
  const getNbsitetraitement = async () => {
    helpers
        .getAllListesitetraitement()
        .then(async r => {
          if (r.status === 200) {
            setNbsitetraitement( r.data.length);
          }
        })
  };
  const getPrestataire = async () => {
    helpers
        .getAllPrestataires()
        .then(async r => {
          if (r.status === 200) {
            setNbprestataire( r.data.length);
          }
        })
  };
  const getEtatdelieu = async () => {
    helpers
        .getetatdelieu()
        .then(async r => {
          if (r.status === 200) {
            setNbnbetatdelieu( r.data.length);
          }
        })
  };



  useEffect(()=>{
    getPointages().catch(console.error);
    getAgents().catch(console.error);
    getListeVehicules().catch(console.error);
    getDepot().catch(console.error);
    getRecuperation().catch(console.error);
    getVidage().catch(console.error);
    getListeTypeDechets().catch(console.error);
    getIncident().catch(console.error);
    getEtatdelieu().catch(console.error);
    getEPI().catch(console.error);
    getNbsitetraitement().catch(console.error);
    getPrestataire().catch(console.error);
    getSite().catch(console.error);
  },[]);

        return (
            <div>
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">Dashboard</h1>
          </div>{/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">

              <li className="breadcrumb-item"><a href="/dashboard">Accueil</a></li>
              <li className="breadcrumb-item active">CITY COLLECT</li>
            </ol>
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    {/* Main content */}
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}
        <div className="row">

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{nbagents}
                {/*<sup style={{fontSize: 20}}>.</sup>*/}
                </h3>
                <p>Agent{nbagents>1 && 's'}</p>
              </div>
              <div className="icon">
                <i className="ion ion-person" />
              </div>
              {/*<Link to="/dashboard/agent" style={{}} className="small-box-footer">*/}
              {/*  Voir plus <i className="fas fa-arrow-circle-right" />*/}
              {/*</Link>*/}
              <a href="/dashboard/agent" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{nbpointage}</h3>
                <p>Pointage{nbpointage>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add" />
              </div>
              <a href="/dashboard/pointage" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-6">
            <div className="small-box bg-secondary">
              <div className="inner">
                <h3>{nbincident}</h3>
                <p>Incident{nbincident>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-empty" />
              </div>
              <a href="/dashboard/incident" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>



          <div className="col-lg-3 col-6">
            <div className="small-box bg-olive">
              <div className="inner">
                <h3>{nbepi}</h3>
                {/*<p>EPI{nbepi>1 && "s"}</p>*/}
                <p>Equipement{nbepi>1 && "s"} partagé{nbepi>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-empty" />
              </div>
              <a href="/dashboard/epi" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-maroon">
              <div className="inner">
                <h3>{nbvidage}</h3>
                <p>Dechet sortant</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <a href="/dashboard/vidage" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{nbdepot}</h3>
                <p>Dépôt Déchet</p>
              </div>
              <div className="icon">
                <i className="ion ion-android-car" />
              </div>
              <a href="/dashboard/depot" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-fuchsia">
              <div className="inner">
                <h3>{nbrecuperation}</h3>
                <p>Récupération</p>
              </div>
              <div className="icon">
                <i className="ion ion-android-car" />
              </div>
              <a href="/dashboard/recuperation" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{nbvehicules}</h3>
                <p>Véhicule{nbvehicules>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-android-car" />
              </div>
              <a href="/dashboard/vehicules" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
              </div>
          </div>

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-blue">
              <div className="inner">
                <h3>{nbtypedechet}</h3>
                <p>Type de dechet{nbtypedechet>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-empty" />
              </div>
              <a href="/dashboard/typedechet" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>




          <div className="col-lg-3 col-6">
            <div className="small-box bg-secondary">
              <div className="inner">
                <h3>{nbprestataire}</h3>
                <p>Prestataire{nbprestataire>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-empty" />
              </div>
              <a href="/dashboard/prestataire" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-6">
            <div className="small-box bg-maroon">
              <div className="inner">
                <h3>{nbsitetraitement}</h3>
                <p>Site{nbsitetraitement>1 && "s"} de traitement{nbsitetraitement>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-empty" />
              </div>
              <a href="/dashboard/sitetraitement" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>


          {isAdmin === "AAAA" &&
           <div className="col-lg-3 col-6">
            <div className="small-box bg-green">
              <div className="inner">
                <h3>{nbsite}</h3>
                <p>Site{nbsite>1 && "s"}</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
              <a href="/dashboard/site" className="small-box-footer">
                Voir plus <i className="fas fa-arrow-circle-right" />
              </a>
            </div>
          </div>}
          {/* ./col */}
        </div>
        {/* /.row */}
        {/* Main row */}
        <div className="row">
          {/* Left col */}
          <section className="col-lg-7 connectedSortable">

          </section>

        </div>
        {/* /.row (main row) */}
      </div>{/* /.container-fluid */}
    </section>
    {/* /.content */}
  </div>
</div>

        )

}
