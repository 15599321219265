import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import ls from "local-storage";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useNavigate    } from "react-router-dom"
import { baseUrl } from "../../services/constantes";
import { styled } from '@mui/system';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import useAutocomplete from '@mui/base/useAutocomplete';
import * as moment from "moment";
import DatePicker from "react-datepicker";
const url_icondechet = baseUrl + "/icondechet/";
let isAdmin = ls.get('xxxx');

const Label = styled('label')({
    display: 'block',
});

const Input = styled('input')(({ theme }) => ({
    width: 220,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

const Listbox = styled('ul')(({ theme }) => ({
    width: 220,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
    },
}));


let columns = [];
if(isAdmin === "AAAA" ) {
 columns = [
     {
         name: 'Site',
         selector: row => row?.site?.nom,
         sortable:true,
         wrap:true,
         style:{fontSize:16}
     },
    {
        name: 'Date',
        selector: row =>moment(row?.createdAt).format("HH:mm:ss DD/MM/yyyy"),
        sortable:true,
        wrap:true,

    },
    {
        name: 'Agent',
        selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom  ,
        // selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom + ' (' + row?.agent?.statut + ')',
        sortable:true,
        wrap:true,

    },
    {
        name: 'Statut',
        selector: row =>   row?.agent?.statut,
        sortable:true,
        wrap:true,

    },
    {
        name: 'Début',
        selector: row => row?.debut,
        cell: d => <span>{moment(d?.debut).format("HH:mm:ss DD/MM/YYYY")}</span>,
        wrap:true,
    },
    {
        name: 'Fin',
        selector: row =>row?.fin !== null ? moment(row?.fin).format("HH:mm:ss DD/MM/YYYY") : '----',
        sortable:true,
        wrap:true,

    },
    {
        name: 'Temps de travail',
      //  selector: row => row?.agent?.nom,
        cell: d => <span>{d?.fin !== null ? (moment.duration(moment(d?.fin).diff(moment(d?.debut))).hours() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).minutes() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).seconds()) : '----'}</span>,
        sortable:true,
        wrap:true,
    },
    {
        name: 'Etat',
        selector: row =>row?.etat !== null ? row?.etat : '----',
        sortable:true,
        wrap:true,

    },
];
}else {
    columns = [
        {
            name: 'Date',
            selector: row =>moment(row?.createdAt).format("HH:mm:ss DD/MM/yyyy"),
            sortable:true,
            wrap:true,

        },
        {
            name: 'Agent',
            selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom  ,
            // selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom + ' (' + row?.agent?.statut + ')',
            sortable:true,
            wrap:true,

        },
        {
            name: 'Statut',
            selector: row =>   row?.agent?.statut,
            sortable:true,
            wrap:true,

        },
        {
            name: 'Début',
            selector: row => row?.debut,
            cell: d => <span>{moment(d?.debut).format("HH:mm:ss DD/MM/YYYY")}</span>,
            wrap:true,
        },
        {
            name: 'Fin',
            selector: row =>row?.fin !== null ? moment(row?.fin).format("HH:mm:ss DD/MM/YYYY") : '----',
            sortable:true,
            wrap:true,

        },
        {
            name: 'Temps de travail',
            //  selector: row => row?.agent?.nom,
            cell: d => <span>{d?.fin !== null ? (moment.duration(moment(d?.fin).diff(moment(d?.debut))).hours() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).minutes() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).seconds()) : '----'}</span>,
            sortable:true,
            wrap:true,
        },
        {
            name: 'Etat',
            selector: row =>row?.etat !== null ? row?.etat : '----',
            sortable:true,
            wrap:true,

        },
    ];
}

export const Centre = () => {
    const navigate = useNavigate();
    const [openModalSuppression, setopenModalSuppression] = useState(false);
    const [selected, setSelected] = useState({ allSelected:false, selectedCount:0, selectedRows:[] });
    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalModif, setshowmodalModif] = useState(false);
    const [modif, setItemModif] = useState({});
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState({columns:columns});
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [vehicules, setVehicules] = useState([]);
    const [DepotOnChange, setDepotOnChange] = useState(null);
    const [agents, setAgents] = useState([]);
    const [AgentOnChange, setAgentOnChange] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const suppression = async (e) => {
        e.preventDefault();
        if(selected.selectedCount==0)
            return;
        setLoading(true);
        helpers
            .supprpointage(selected.selectedRows)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    getPointage().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false);
                }else{
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false)
                }
            }).catch((r)=>{
                setOpenToast({
            isOpenToast: true,
            txt: r.message,
        });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

        })
    };



    useEffect(()=>{
        const filteredPersons = tables.data && tables.data.filter(
            item => {
                return (
                    item?.agent?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    ||
                    item?.agent?.prenom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    ||
                    item?.site?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == ''){
            getPointage().catch(console.error);
        }
        setTables({...tables, data: filteredPersons});
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function newDepot(e) {
        e.preventDefault();
        setshowmodal(true);
    }


    function rechercherpardate(e) {

        setLoading(true);
        e.preventDefault();
        helpers
            .pointagepardate({startDate:startDate, endDate:endDate})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables({...tables, data: r.data});
                }
            })

    }


    const getPointage = async () => {
        helpers
            .getpointage()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    console.log('rdta',r.data);
                    setTables({...tables, data: r.data});
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getPointage().catch(console.error);

        const getVehiculeToDropdown = async () => {
            helpers
                .getVehiculetodropdown()
                .then(async r => {
                    setLoading(false);
                    if (r.status === 200) {
                        setVehicules(r.data);
                    }
                })
        };
        getVehiculeToDropdown().catch(console.error)
    },[]);

    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: vehicules,
        getOptionLabel: (option) =>{return  option.label},
        onChange: async (option, val) =>{
            console.log('op', val);
            await setDepotOnChange(val);
        },
    });

    const {
        getRootProps1,
        getInputLabelProps1,
        getInputProps1,
        getListboxProps1,
        getOptionProps1,
        groupedOptions1,
    } = useAutocomplete({
        id: 'use-autocomplete-demo1',
        options: agents,
        getOptionLabel: (option) =>{return  option.label},
        onChange: async (option, val) =>{
            console.log('op', val);
            await setAgentOnChange(val);
        },
    });
    const modificationDepot =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const prenom = data.get('prenom');
        const email = data.get('email');
        const telephone = data.get('telephone');
        const adresse = data.get('adresse');
        const password = data.get('password');
        const newpassword = data.get('newpassword');

        setLoadingSave(true);
        let dataX = {
            id:modif.id,
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            password: password,
            newpassword: newpassword,
        };

        helpers
            .donnerieupdate(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getPointage().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalModif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });


    };

    const savenewDepot =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const prenom = data.get('prenom');
        const email = data.get('email');
        const telephone = data.get('telephone');
        const adresse = data.get('adresse');
        const password = data.get('password');




        setLoadingSave(true);
        let dataX = {
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            password: password
        };

        helpers
            .depotsave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getPointage().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    



  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Pointage</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    Pointage
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                    City collect
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                {/*<button*/}
                {/*    onClick={(e)=>newDepot(e)}*/}
                {/*    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              {/*<div className="card-header">*/}
              {/*    <h3 className="card-title">DataTable with default features</h3>*/}
              {/*</div>*/}

              <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                      <button
                          onClick={(e)=>setopenModalSuppression(true)}
                          className={"btn btn-danger px-4"}><i className="fas fa-trash mr-2" />Supprimer</button>
                      <div  style={{ marginLeft:10, top:20, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div style={{marginRight:5}}>Début</div>
                          <div style={{}}>
                              <DatePicker locale="fr" showTimeSelect dateFormat="Pp" selected={startDate} onChange={(date) => setStartDate(date)} />
                          </div>
                      </div>
                      <div  style={{  marginLeft:10, marginRight:10, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div style={{}}>Fin</div>
                          <div style={{marginLeft:5}}>
                              <DatePicker locale="fr" showTimeSelect dateFormat="Pp"   selected={endDate} onChange={(date) => setEndDate(date)} />
                          </div>
                      </div>
                      <button
                          onClick={(e)=>rechercherpardate(e)}
                          className={"btn btn-info px-2"}><i className="fas fa-search mr-2" />Rechercher</button>
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  </div>

                  <DataTableExtensions
                      {...tables}
                      filter={false}
                      //filterHidden={false}
                     print={false}
                     export={false}
                     filterPlaceholder={'Rechercher'}
                  >
                  <DataTable
                      pagination
                      selectableRowsComponentProps={selectProps}
                      dense
                      selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      // onRowClicked={(row, event) => {
                      //     setItemModif(row);
                      //         setshowmodalModif(true)
                      // }}
                      onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                          const se = { allSelected:allSelected, selectedCount:selectedCount, selectedRows:selectedRows };
                            setSelected(se)
                      }}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
                  </DataTableExtensions>
              </div>
              {/*fin body*/}
          </div>

          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Dépôt Déchet</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={savenewDepot} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                      <div   className="modal-body">

                          <div  style={{  marginBottom: "3rem" }}>
                              <div {...getRootProps()}>
                                  <Label {...getInputLabelProps()}>Véhicule</Label>
                                  <Input {...getInputProps()} />
                              </div>
                              {groupedOptions.length > 0 ? (
                                  <Listbox {...getListboxProps()}>
                                      {groupedOptions.map((option, index) => (
                                          <li {...getOptionProps({ option, index })}>{option.label}</li>
                                      ))}
                                  </Listbox>
                              ) : null}
                          </div>

                          {/*<div  style={{  marginBottom: "3rem" }}>*/}
                          {/*    <div {...getRootProps1()}>*/}
                          {/*        <Label {...getInputLabelProps1()}>Agent</Label>*/}
                          {/*        <Input {...getInputProps()} />*/}
                          {/*    </div>*/}
                          {/*    {groupedOptions1.length > 0 ? (*/}
                          {/*        <Listbox {...getListboxProps1()}>*/}
                          {/*            {groupedOptions1.map((option, index) => (*/}
                          {/*                <li {...getOptionProps1({ option, index })}>{option.label}</li>*/}
                          {/*            ))}*/}
                          {/*        </Listbox>*/}
                          {/*    ) : null}*/}
                          {/*</div>*/}

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              autoComplete="email"
                              autoFocus
                              //error
                              type={'email'}
                          />

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="telephone"
                              label="Téléphone"
                              name="telephone"
                              autoComplete="telephone"
                              autoFocus
                              type={'tel'}

                          />


                          <TextField
                              margin="normal"
                            //  required
                              fullWidth
                              id="adresse"
                              label="Adresse"
                              name="adresse"
                              autoComplete="adresse"
                              autoFocus
                              multiline
                          />

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="password"
                              label="Mot de passe"
                              name="password"
                              autoComplete="password"
                              autoFocus
                          />


                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">
                          <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                          <Button
                              onPress = {savenewDepot}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              Enregistrer
                          </Button>
                      </div>
                      </Box>

                  </div>
              </div>


          </Modal>

          <Modal
              open={isshowmodalModif}
              onClose={()=>{setshowmodalModif(false);}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Modification dépôt</h4>
                          <button onClick={()=>{setshowmodalModif(false);}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={modificationDepot} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >
                          <div   className="modal-body">

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="prenom"
                                  label="Prénom"
                                  name="prenom"
                                  autoComplete="prenom"
                                  value={modif.prenom}
                                  onChange={(value)=>{
                                      setItemModif({...modif, prenom: value.target.value})
                                  }}
                              />


                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="email"
                                  label="Email"
                                  name="email"
                                  autoComplete="email"
                                  value={modif.email}
                                  onChange={(value)=>{
                                      setItemModif({...modif, email:value.target.value})
                                  }}

                              />

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="telephone"
                                  label="Téléphone"
                                  name="telephone"
                                  autoComplete="telephone"
                                  value={modif.telephone}
                                  onChange={(value)=>{
                                      setItemModif({...modif, telephone:value.target.value})
                                  }}

                              />


                              <TextField
                                  margin="normal"
                                  //  required
                                  fullWidth
                                  id="adresse"
                                  label="Adresse"
                                  name="adresse"
                                  autoComplete="adresse"
                                  autoFocus
                                  mutliline
                                  value={modif.adresse}
                                  onChange={(value)=>{
                                      setItemModif({...modif, adresse:value.target.value})
                                  }}

                              />




                          </div>
                          <div className="modal-footer d-flex flex-row justify-content-between">
                              <button onClick={()=>{setshowmodalModif(false);}} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                              <Button
                                  onPress = {modificationDepot}
                                  type="submit"
                                  variant="contained"
                                  startIcon={
                                      isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                          : <SaveIcon fontSize="inherit" />
                                  }
                              >
                                  Enregistrer
                              </Button>
                          </div>
                      </Box>
                  </div>
              </div>
          </Modal>

          <Dialog
              open={openModalSuppression}
              keepMounted
              onClose={()=>setopenModalSuppression(false)}
              aria-describedby="alert-dialog-slide-description"   >
              <DialogTitle>{"Voulez-vous vraiment supprimer?"}</DialogTitle>
              <DialogActions>
                  <Button onClick={()=>setopenModalSuppression(false)}>Annuler</Button>
                  <Button onClick={suppression}>Ok</Button>
              </DialogActions>
          </Dialog>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
      </LoadingOverlay>
  )
};
