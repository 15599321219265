import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Checkbox from "@mui/material/Checkbox/Checkbox";



const columns = (( ShowConfirmDelete) => [
    {
        name: 'Incident',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Commentaire',
        selector: row => row.remarque,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);





export const Centre = () => {
    const navigate = useNavigate();
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({ isOpenNomError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [Incident, setIncident] = React.useState("");
    const [idlistIncident, setIdlistIncident] = React.useState(null);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [isshowmodal, setshowmodal] = React.useState(false);
    const [comment, setComment] = React.useState("");

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
        .supprListincident({id: idtosuppr})
        .then(async r => {
            setLoading(false);
            if (r.status === 200) {
                setOpenToast({
                    isOpenToast: true,
                    txt: r.message
                });
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                getListIncidents().catch(console.error);
                setOpen(false);
            }
        })
    };

    useEffect(()=>{
        const filteredTables = tables.data && tables.data.filter(
            item => {
                return (
                    item
                        .nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    || item
                        .remarque
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getListIncidents().catch(console.error);
        setTables(filteredTables);
    },[searchField]);




    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    



    const getListIncidents = async () => {
        helpers
            .getAllListincident()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };



    useEffect( ()=>{
        async function init(){
            setLoading(true);
            await getListIncidents().catch(console.error);
        }
        init();
    },[]);


    const modificationlistIncident =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('remarque');

        if(Incident.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez choisir l\'incident' });
            return;
        }

        setOpenToast({...openSnack, setOpenToast:false});

        setLoadingSave(true);
        let body = {
            nom: Incident.trim(),
            id:idlistIncident
            //commentaire: commentaire
        };

        if(comment !== "")
            body.remarque = comment

        if(idlistIncident != null)
        helpers
            .listIncidentmodification(body)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getListIncidents().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };


    const ajoutlistIncident =(e)=> {
        const data = new FormData(e.currentTarget);
        e.preventDefault();
        if(Incident.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez insérer l\'incident' });
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});


        setLoadingSave(true);
        let dataX = {
            nom: Incident
        };

        if(comment !== "")
            dataX.remarque = comment

        helpers
            .listincidentsave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setshowmodal(false);
                    await getListIncidents().catch(console.error);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };



    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Liste des incidents</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Incident
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                            <button
                               onClick={(e)=>{
                                   e.preventDefault()
                                   setIncident("")
                                   setshowmodal(true)
                               }}
                               // onPress={(e)=>{
                               //    }}
                                className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                        <DataTable
                            pagination
                            selectableRowsComponentProps={selectProps}
                            dense
                            columns={columns( ShowConfirmDelete)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                            setIncident(row?.nom);
                            setIdlistIncident(row?.id);
                            setshowmodalmodif(true);
                            }}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>

                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification incident</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationlistIncident} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="incident"
                                        label="Incident"
                                        name="incident"
                                        autoFocus
                                        value={Incident}
                                        onChange={(value)=>{setIncident(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                       // required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        //  autoComplete="nom"
                                        multiline={true}
                                        rows={3}
                                        autoFocus
                                        value={comment}
                                        onChange={(value)=>{setComment(value.target.value); }}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationlistIncident}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajout incident</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={ajoutlistIncident} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="incident"
                                        label="Incident"
                                        name="incident"
                                        autoFocus
                                        value={Incident}
                                        onChange={(value)=>{setIncident(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                      //  required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        //  autoComplete="nom"
                                        multiline={true}
                                        rows={3}
                                        autoFocus
                                        value={comment}
                                        onChange={(value)=>{setComment(value.target.value); }}
                                    />

                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {ajoutlistIncident}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>


                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression incident
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>

        </LoadingOverlay>
    )
};
