import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Authentication} from "./newScreen/Authentication";
import Dashboard from "./newScreen/centre/Dashboard";
import Pointage from "./newScreen/pointage/Pointage";
import { BrowserRouter as Router, Routes, Route, Link, HashRouter  } from "react-router-dom";
import Admin from "./newScreen/admin/Admin";
import ListerVehiculeprestataire from "./newScreen/listerVehiculeprestataire/ListerVehicule";
import ListerVehicule from "./newScreen/listerVehicule/ListerVehicule";
import AjoutVehicule from "./newScreen/AjoutVehicule/AjoutVehicule";
import AjoutVehiculeprestataire from "./newScreen/AjoutVehiculeprestataire/AjoutVehicule";
import Rapport from "./newScreen/rapport/Rapport";
import CategorieDechet from "./newScreen/categorieDechet/CategorieDechet";
import TypeDechet from "./newScreen/typeDechet/TypeDechet";
import Agent from "./newScreen/agent/Agent";
import Depot from "./newScreen/depot/Depot";
import Recuperation from "./newScreen/recuperation/Recuperation";
import ListIncident from "./newScreen/listIncident/ListIncident";
import Incident from "./newScreen/incident/Incident";
import Vidage from "./newScreen/dechetsortant/Vidage";
import Etatdelieu from "./newScreen/fichedouverture/Etatdelieu";
import Listeetatdelieu from "./newScreen/listFichedouverture/ListEtatdelieu";
import ListEpi from "./newScreen/listEpi/ListEpi";
import Epi from "./newScreen/epi/Epi";
import Unitedechet from "./newScreen/unitedechet/unitedechet";
import Site from "./newScreen/sites/Site";
import Prestataire from "./newScreen/prestataires/Prestataires";
import Sitetraitement from "./newScreen/sitetraitement/Sitetraitement";
import Typevehicule from "./newScreen/typevehicule/Typevehicule";

const PrivateRouters = ()  => {
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/pointage" element={<Pointage />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/AjoutVehicule" element={<AjoutVehicule />} />
            <Route path="/AjoutVehiculeprestataire" element={<AjoutVehiculeprestataire />} />
            <Route path="/vehicules" element={<ListerVehicule />} />
            <Route path="/vehiculeprestataire" element={<ListerVehiculeprestataire />} />
            <Route path="/categoriedechet" element={<CategorieDechet />} />
            <Route path="/typedechet" element={<TypeDechet />} />
            <Route path="/sites" element={<Site />} />
            <Route path="/rapport" element={<Rapport />} />
            <Route path="/agent" element={<Agent />} />
            <Route path="/depot" element={<Depot />} />
            <Route path="/recuperation" element={<Recuperation />} />
            <Route path="/listeincident" element={<ListIncident />} />
            <Route path="/incident" element={<Incident />} />
            <Route path="/listetatdelieu" element={<Listeetatdelieu />} />
            <Route path="/etatdelieu" element={<Etatdelieu />} />
            <Route path="/vidage" element={<Vidage />} />
            <Route path="/etatdelieu" element={<Etatdelieu />} />
            <Route path="/listepi" element={<ListEpi />} />
            <Route path="/epi" element={<Epi />} />
            <Route path="/unitedechet" element={<Unitedechet />} />
            <Route path="/prestataire" element={<Prestataire />} />
            <Route path="/site" element={<Site />} />
            <Route path="/sitetraitement" element={<Sitetraitement />} />
            <Route path="/typevehicule" element={<Typevehicule />} />
        </Routes>
    )
};

ReactDOM.render(
  <React.StrictMode>
      <Router >
          <Routes >
              <Route path="/*" element={<Authentication />} />
              <Route
                  path="/dashboard/*"
                  element={
                      <PrivateRouters />
                  }
              />
          </Routes>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
