import React, {createContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import {helpers} from "../../services/api/helpers";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import DataTable from "react-data-table-component";
import Collapse from "@mui/material/Collapse/Collapse";
import {Alert} from "../Authentication";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Papa from "papaparse";


const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const allowedExtensions = ["csv"];

const AjouterPrestatairevrai = (props) => {



    const newPrestatairevrai =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        // const societe = data.get('societe');
        const nom = data.get('nom');
        const prenom = data.get('prenom');
        const adresse = data.get('adresse');
        const ville = data.get('ville');
        const telephone = data.get('telephone');
        const email = data.get('email');
        const isemail = validateEmail(email);
        if(!isemail){
            props.setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez ajouter un e-mail valide.'
            });
            return;
        }

        let dataX = {
            nom: nom,
            prenom: prenom,
            adresse: adresse,
            ville: ville,
            telephone: telephone,
            email: email
        };

        props.setParentLoading(true);
        helpers
            .ajouterprestatairevrai(dataX)
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    props.getPrestatairevrai().catch(console.error);
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    props.setSwitchPrestatairevrai({target:{value:'Afficher'}})
                } else {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    props.setSwitchPrestatairevrai({target:{value:'Afficher'}})
                }
            })
            .catch(e => {
                props.setParentLoading(false);
                props.setSwitchPrestatairevrai({target:{value:'Afficher'}})
            });
    };



    useEffect(()=>{
       // props.setParentLoading(true);
    },[]);


    return (
        <div>
        <Box component="form" onSubmit={newPrestatairevrai}  sx={{ mt: 1 }}
             Validate
             autoComplete="on"   >
        <div className="column" >
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nom"
                    label="Nom"
                    name="nom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="prenom"
                    label="Prénom"
                    name="prenom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    //required
                    fullWidth
                    id="adresse"
                    label="Adresse"
                    name="adresse"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    //required
                    fullWidth
                    id="ville"
                    label="Ville"
                    name="ville"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                   // required
                    fullWidth
                    id="telephone"
                    label="Téléphone"
                    name="telephone"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    //required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    type={'email'}
                    //  autoComplete="nom"
                />
            </div>



            <div style={{marginTop:15}} className="row justify-content-end">
                <button
                    onPress = {newPrestatairevrai}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer Prestataire</button>
            </div>
        </div>
        </Box>

        </div>
    );
}

export default AjouterPrestatairevrai;
