import React, {useEffect, useState} from 'react'
import ls from "local-storage";
import {  useNavigate    } from "react-router-dom"
import {Link} from "react-router-dom";

export default ()=> {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const localstorage = async () => {
            const user_ = await JSON.parse(await ls.get('user'));
            setUser(user_);
        };
        localstorage().catch(console.error);
    });
    //

    const setlogOut =async (event)=> {
        event.preventDefault();
        await ls.remove('userToken');
        await ls.remove('xxxx');
        navigate("/", { replace: true });
    };

    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav d-flex align-items-center">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item">
                        {user != null && user.site && <span style={{fontWeight:'bold'}}>
                            Site: <span style={{fontWeight:'normal'}}>{user.site}</span>
                        </span>}
                    </li>
                </ul>

                <ul  style={{ flexDirection:'row', alignItems:'center'}}  className="navbar-nav ml-auto">
                        <div  style={{marginRight:20, color:'#575757',cursor:"pointer",}} className="link">
                            {user != null && user.email}
                        </div>

                    <i className="far fa-user" />
                    <div   onClick={setlogOut} style={{marginLeft:10, cursor:"pointer",}}>
                        Se deconnecter
                    </div>
                    <li className="nav-item">
                        {/*<a className="nav-link" data-widget="fullscreen" href="/dashboard" role="button">*/}
                        {/*    <i className="fas fa-expand-arrows-alt"></i>*/}
                        {/*</a>*/}
                    </li>
                </ul>

            </nav>
        </div>

    )

}
