import React, { createContext, useState} from 'react'
import Header from "../Header";
import Menu from "./Menu";
import {Centre} from "./Centre";
import Footer from "../Footer";



function Sitetraitement() {

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
            <Centre  />
            <Footer/>
        </div>
    );
}

export default Sitetraitement;
