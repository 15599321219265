import { baseUrl } from "../constantes";
import ls from "local-storage";
//import { useSelector } from "react-redux";

export const helpers = {
    login: async data => {
        const response = await (
            await fetch(baseUrl + "/admins/login", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllPrestataires: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestataires/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllPrestatairevrai: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestatairevrai/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    prestatairemodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestataire/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    prestatairevraimodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestatairevrai/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    setAgentAdmin: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/agent/setAdmin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprVehicule: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicule/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprVehiculeprestataire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehiculeprestataire/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprSite: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprUnitedechet: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/supprUnitedechet", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprPrestataire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestataire/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprPrestatairevrai: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestatairevrai/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprvidage: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprEPI: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/epi/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprincident: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/incident/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprListsitetraitement: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitetraitement/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprListtypevehicule: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typevehicule/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    suppretatdelieu: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/etatdelieu/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprpointage: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/pointage/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprdonnerie: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/donnerie/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllVehicules: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicules/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllVehiculeprestataire: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehiculeprestataire/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getEPI: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/epi/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getincident: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/incident/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },

    getetatdelieu: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/etatdelieu/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getvidage: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    saveVehicle: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicle/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    saveVehicleprestataire: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicleprestataire/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    categoriesave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/categoriedechets/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listEPIsave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listepi/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listincidentsave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listincident/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listsitetraitementsave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitetraitement/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listTypevehiculesave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typevehicule/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listetatdelieusave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listetatdelieu/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    unitedechetsave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/unitedechet", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllUnitedechet: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/getAllUnitedechet", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllCategorieDechets: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/categoriedechets/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllListEPI: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listepi/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllListincident: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listincident/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllListesitetraitement: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitetraitement/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllListtypevehicule: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typevehicule/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllListetatdelieu: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listetatdelieu/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    categorieDechetmodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/categoriedechets/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listEPImodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listepi/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listIncidentmodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listincident/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listeSitetraitementmodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitetraitement/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listeTypevehiculemodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typevehicule/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    listEtatdelieumodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listetatdelieu/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprListEPI: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listepi/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprListincident: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listincident/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprCategorieDechet: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/categoriedechets/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprTypeDechet: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typedechets/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllTypeDechets: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typedechets/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    typeDechetmodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typedechets/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    typesave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/typedechets/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    agentsave: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/agent/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprAgent: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/agent/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllAgents: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/agent/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    agentmodification: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/agent/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllCategoriesToDropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/categorietodropdown/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getdonnerie: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/donnerie/get", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getVehiculetodropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicules/gettodropdown", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getVehiculeprestatairetodropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehiculeprestataire/gettodropdown", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getpointage: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/pointage/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    supprListetatdelieu: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/listetatdelieu/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    pointagepardate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/pointage/rechercherpardate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    vidagepardate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/rechercherpardate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    rechercherpardate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/depot/rechercherpardate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },

    sendVehicules: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicules/savebyfiles", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },






    recuperermdp: async data => {
        const response = await (
            await fetch(baseUrl + "/admins/recuperermdp", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminsave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },

    supprPlageHoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/update", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdateWithoutPassword: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/updateWithoutPassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprAdmin: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    excel: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/excel", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getadmins: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    ajouterprestataire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestataire/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    ajouterprestatairevrai: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/prestatairevrai/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllUsagers: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usagers/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },

    getMarques: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/marques/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getTypes: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/types/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },

    getAllSites: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSiteswithtoken: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/getAllwithtoken", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSitesParGroupe: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitepargroupe/getall/"+id, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    sitesave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    // sitemodification
    unitedechetmodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vidage/unitedechetmodification", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    modificationsite: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getVehiculesParSite: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehiculeparsite/getall/"+id, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    vehiculemodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicle/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },

    ajouterplagehoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    modifierplagehoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getPlagehoraire: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },




    /////////////////////////////////////////////////////////
    contacteradmin: async (data, token) => {
        const response = await (
            await fetch(baseUrl + "/user/contacteradmin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    updateProfil: async (data, token) => {
        const response = await (
            await fetch(baseUrl + "/user/updateProfil", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    verifcompte: async (data, token) => {
        const response = await (
            await fetch(baseUrl + "/user/verifcompte", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },

};
