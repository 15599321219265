// const baseUrl = "https:/onlineurl.eu";//active
// const images = baseUrl + "/images/";

/*    const baseUrl = "http://localhost:3000";
    const baseUrlSocket = "http://localhost:3000/";*/

const baseUrl = "https://citycollect.axesinnovations.net";
const baseUrlSocket = "https://citycollect.axesinnovations.net/";

const googleapi = {
  baseurl: "https://maps.googleapis.com/maps/api/",
  token: "AIzaSyDPIlJCMr6sgNWSpegNcVXd76mcIuPPFhU"
};



export {
  baseUrl,
  googleapi,
  baseUrlSocket
};
