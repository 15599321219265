import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";



const columns = (( ShowConfirmDelete) => [
    {
        name: 'Nom site',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Adresse',
        selector: row => row?.adresse,
        wrap:true,
        sortable:true,
    },

    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);


export const Sites = () => {

    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        isOpenGroupeError:false,
        isOpenPlagehoraireError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [nommodif, setnommodif] = useState("");
    const [adressemodif, setadressemodif] = useState("");
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idmodif, setidmodif] = useState(null);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const ShowConfirmDelete = async (val) => {
        console.log('target id', await val.target.id);
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprSite({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    getSites().catch(console.error);
                    setOpen(false);
                }
            })
    };


    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item?.adresse !== null && item?.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getSites().catch(console.error);
        if(filteredTables !== undefined)
        setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function newGroupes(e) {
        e.preventDefault();
        setshowmodal(true);
    }

    const getSites = async () => {
        helpers
            .getAllSites()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);

                }
            })
    };



    useEffect(()=>{
        setLoading(true);
        getSites().catch(console.error);
    },[]);


    const savenewSite =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const adresse_ = data.get('adresse');
        const commentaire = data.get('commentaire');
        if(nom == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du site'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});


        setLoadingSave(true);
        let dataX = {
            nom: nom,
            adresse: adresse_,
            commentaire: commentaire
        };

        helpers
            .sitesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                getSites().catch(console.error);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const modification =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        if(nommodif == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Le site ne pourrait être pas vide.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});


        setLoadingSave(true);

        let dataX = {
            nom: nommodif,
            adresse:  adressemodif,
            commentaire: commentairemodif,
            id: idmodif,
        };


        helpers
            .modificationsite(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getSites().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const handleClickChip = () => {
        console.info('You clicked the Chip.');
    };

    
    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Sites</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Sites
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        <button
                            onClick={(e)=>newGroupes(e)}
                            className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}   >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        <DataTable
                            pagination
                            selectableRowsComponentProps={selectProps}
                            dense
                            columns={columns( ShowConfirmDelete)}
                            data={tables}
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={async (row, event) => {
                                setnommodif(row?.nom);
                                setadressemodif(row?.adresse || "");
                                setcommentairemodif(row?.commentaire || "");
                                setidmodif(row?.id);
                                setshowmodalmodif(true);
                            }}

                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Nouvelle site</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={savenewSite} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        //  required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                    />
                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>


                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="adresse"
                                        label="Adresse"
                                        name="adresse"
                                        autoComplete="adresse"
                                    />
                                    <Collapse in={openSnack.isOpenAdresseError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenAdresseError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>

                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        autoFocus
                                    />


                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {savenewSite}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification site</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <Box component="form" onSubmit={modification} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                        value={nommodif}
                                        onChange={(e)=>setnommodif(e.target.value)}
                                    />

                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>

                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="adressemodif"
                                        label="Adresse"
                                        name="adressemodif"
                                        autoComplete="adressemodif"
                                        autoFocus
                                        value={adressemodif}
                                        onChange={(e)=>setadressemodif(e.target.value)}
                                    />

                                    <Collapse in={openSnack.isOpenAdresseError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenAdresseError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        value={commentairemodif}
                                        onChange={(e)=>setcommentairemodif(e.target.value)}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modification}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression site
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
    )
};
